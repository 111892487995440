import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip } from "chart.js";

Chart.register(ArcElement, Tooltip);

const DoughnutChart = () => {
  const data = {
    labels: [
      "Public",
      "Seed",
      "Advisory",
      "Pre Seed",
      "Ecosystem",
      "Marketing",
      "Liquidity",
      "Team",
      "Private",
    ],
    datasets: [
      {
        data: [9, 6, 5, 5, 22, 16, 15, 12, 10],
        backgroundColor: [
          "#09729B",
          "#086589",
          "#06465F",
          "#053A4F",
          "#84DCFF",
          "#4CC8F9",
          "#128CBC",
          "#40C9FF",
          "#149FD6",
          "#128CBC",
        ],
        borderWidth: 2,
        borderColor: "#fff",
        hoverBorderColor: "#fff",
      },
    ],
  };

  const options = {
    responsive: true,
    cutout: "60%", // Size of the center cutout
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false, // Disable the default tooltip
        external: function (context) {
          // Tooltip Element
          let tooltipEl = document.getElementById("chartjs-tooltip");

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.style.opacity = 0;
            tooltipEl.style.position = "absolute";
            tooltipEl.style.background = "#128CBC";
            tooltipEl.style.borderRadius = "12px";
            tooltipEl.style.pointerEvents = "none";
            // tooltipEl.style.border = "1px solid #FFFFFF1A";
            tooltipEl.style.padding = "8px 16px";
            tooltipEl.style.color = "#fff";
            const fontSize = window.innerWidth <= 768 ? '9.48px' : '20.69px';
            tooltipEl.style.fontSize = fontSize;
            tooltipEl.style.fontWeight = "700";
            tooltipEl.style.textAlign = "center";
            tooltipEl.style.fontFamily = "Poppins";
            // tooltipEl.style.boxShadow = "0px 4px 8px rgba(0, 0, 0, 0.1)";
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set Text
          if (tooltipModel.body) {
            const tooltipText = tooltipModel.body[0].lines[0];
            tooltipEl.innerHTML = tooltipText;
            const percentage = tooltipModel.dataPoints[0].raw; // Percentage value
            const label = tooltipModel.dataPoints[0].label;
            tooltipEl.innerHTML = `<div>${percentage}%  ${label}</div>`;
          }

          const position = context.chart.canvas.getBoundingClientRect();

          // Position Tooltip
          tooltipEl.style.opacity = 1;
          tooltipEl.style.left =
            position.left +
            window.pageXOffset +
            tooltipModel.caretX -
            tooltipEl.clientWidth / 2 +
            "px";
          tooltipEl.style.top =
            position.top +
            window.pageYOffset +
            tooltipModel.caretY -
            tooltipEl.clientHeight -
            10 +
            "px";
        },
      },
    },
    onHover: (event, elements) => {
      if (elements.length) {
        event.native.target.style.cursor = "pointer";
      } else {
        event.native.target.style.cursor = "default";
      }
    },
  };

  return (
    <div
      style={{
        position: "relative",
        width: "453px",
        margin: "auto",
        padding: "44px",
        boxSizing: "content-box",
        border: "4px solid #139ED5",
        borderRadius: "50%",
      }}
      className="Doughnut__res"
    >
      <Doughnut data={data} options={options} />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "24px",
          textAlign: "center",
          fontFamily: "Poppins, sans-serif",
        }}
      >
        <div
          style={{
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "500",
            color: "#323232",
            lineHeight: "21.06px",
          }}
          className="Doughnut__res-p"
        >
          Total Supply
        </div>
        <div
          style={{
            fontSize: "49.04px",
            fontWeight: "700",
            color: "#139ED5",
            lineHeight: "73.56px",
          }}
          className="Doughnut__res-h"
        >
          1 Billion
        </div>
      </div>
    </div>
  );
};

export default DoughnutChart;
