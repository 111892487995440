import React from "react";
import "./Terms.css";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/ContactUs/Navbar/Navbar";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import XIcon from "@mui/icons-material/X";
import { useNavigate } from "react-router-dom";

const iconStyle = {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#323232", // Default background color
  borderRadius: "50%",
  padding: "10px",
  transition: "background-color 0.3s",
  cursor: "pointer",
};
const iconStyleSm = {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  // backgroundColor: "white",
  borderRadius: "50%",
  padding: "5px",
  transition: "background-color 0.3s",
  cursor: "pointer",
};
const iconStyleInnerSm = {
  color: "white",
  fontSize: "14px", // Icon size
};
const iconHoverStyle = {
  backgroundColor: "#139ED5", // Background color on hover
};

const iconStyleInner = {
  color: "white", // Icon color
  fontSize: "18px", // Icon size
};
const Terms = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="home__main">
        <div className="home__main-left">
          <div>
            <img
              src="/ContactUS_Images/logo.svg"
              alt="logo"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            />
          </div>
          <div className="Contact-Top1-inner">
            <div className="terms-Top1-1">
              <p className="Contact-Top1-1-text">FOLLOW US</p>
              <p className="Contact-Top1-1-text1">
                Feel free to follow us on social media, you can get all the news
                and update on our platform and its easier to stay up to date
              </p>
              <div className="Contact-Top1-1-1">
                <div
                  style={iconStyle}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconHoverStyle.backgroundColor)
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconStyle.backgroundColor)
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      "https://www.instagram.com/evox.token/?fbclid=IwZXh0bgNhZW0CMTEAAR2lp9m3bnCTjpf06m6Up8JdAdaPNE25NTkyuJpfO34bVLS0s0TS3hONQT0_aem_Koc2xR2nywzOAMi6L3RFXA",
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  <InstagramIcon style={iconStyleInner} />
                </div>
                <div
                  style={iconStyle}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconHoverStyle.backgroundColor)
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconStyle.backgroundColor)
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      "https://t.me/evoxtoken",
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  <TelegramIcon style={iconStyleInner} />
                </div>
                <div
                  style={iconStyle}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconHoverStyle.backgroundColor)
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconStyle.backgroundColor)
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      "https://x.com/EvoxToken",
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  <XIcon style={iconStyleInner} />{" "}
                  {/* X is represented by TwitterIcon */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="terms__main-right">
          <div className="navnardiv">
            <Navbar />
            <div className="terms-main">
              <div className="terms-top-heading">
                <h2 className="evox-text-main-heading2">
                  EVOX <br /> Terms of service
                </h2>
                <p className="restricted-text">
                  Please be aware that Evox services and products are not
                  available to residents of the United States, Canada, and other
                  restricted jurisdictions. Evox operates strictly within the
                  legal frameworks applicable to our global operations and is
                  committed to regulatory compliance.
                </p>
              </div>
            </div>
          </div>
          <div className="home-Top1-inner">
            <div className="home-Top1-2">
              <div>
                <img src="/Images/Home/globe.svg" alt="globe" />
              </div>
              <div>
                <p className="home-Top1-1-text">FOLLOW US</p>
                <p className="home-Top1-1-text1">
                  Feel free to follow us on social media, you can get all the
                  news and update on our platform and its easier to stay up to
                  date
                </p>
              </div>
              <div className="home-Top1-1-1">
                <div
                  style={iconStyleSm}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconHoverStyle.backgroundColor)
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconStyle.backgroundColor)
                  }
                >
                  <InstagramIcon style={iconStyleInnerSm} />
                </div>
                <div
                  style={iconStyleSm}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconHoverStyle.backgroundColor)
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconStyle.backgroundColor)
                  }
                >
                  <TelegramIcon style={iconStyleInnerSm} />
                </div>
                <div
                  style={iconStyleSm}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconHoverStyle.backgroundColor)
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconStyle.backgroundColor)
                  }
                >
                  <XIcon style={iconStyleInnerSm} />{" "}
                  {/* X is represented by TwitterIcon */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>








      
      <div className="privacy__section_2" style={{paddingTop:"34px"}}>
        <div className="Terms__textsection">
          <div className="terms-text-heading">
            <h1>Introduction</h1>
            <p>
              Welcome to www.evoxtoken.io (the “Website”). These Terms of
              Service (“Terms”) govern your access to and use of the Website,
              encompassing all content, features, and services offered through
              the Evox platform. By accessing or using our platform, you agree
              to comply with these Terms and our Privacy Policy, which is
              incorporated herein by reference.
            </p>
          </div>

          <div className="terms-text-heading">
            <h1>Eligibility</h1>
            <p>
              By using Evox, you affirm that you are at least 18 years of age
              and are not a resident of a restricted jurisdiction, including the
              U.S. and Canada. Evox’s services are intended for users familiar
              with real estate investment and blockchain technology. You agree
              that you have the legal capacity to enter into this agreement and
              understand the risks associated with cryptocurrency and tokenized
              real estate investments.
            </p>
          </div>

          <div className="terms-text-heading">
            <h1>User Accounts</h1>
            <p>
              To access specific features of the Evox platform, including
              purchasing tokenized property shares (NFTs) or participating in
              crowdfunding, you are required to create an account. Account
              creation involves providing personal details such as your name and
              email, linking a Web3 wallet (e.g., MetaMask), and agreeing to
              Evox’s terms. Evox operates under a decentralized model,
              minimizing KYC procedures, but we may still require verification
              depending on regulatory requirements.
            </p>
          </div>

          <div className="terms-text-heading">
            <h1>Prohibited Activities</h1>
            <p>
              <ul>
                <li>Engaging in fraudulent transactions or illegal schemes</li>
                <li>
                  Attempting to hack or interfere with the platform’s security
                  or operations
                </li>
                <li>Misrepresenting yourself or your intentions</li>
                <li>
                  Using the platform for money laundering, terrorism financing,
                  or other unlawful purposes
                </li>
              </ul>
            </p>
          </div>

          <div className="terms-text-heading">
            <h1>Tokenized Real Estate and Asset Transactions</h1>
            <p>
              Evox enables fractional ownership of real estate properties
              through tokenization, where users can invest in property shares
              (NFTs). By participating in any tokenized real estate transaction,
              you acknowledge that:
              <ul>
                <li>
                  Evox tokens or other cryptocurrencies may be used to pay for
                  transactions and fees.
                </li>
                <li>
                  Transactions and records on the blockchain are immutable and
                  irreversible once executed.
                </li>
                <li>
                  There are inherent risks, including market fluctuations,
                  regulatory changes, and liquidity constraints, that could
                  impact the value of your investments.
                </li>
              </ul>
            </p>
          </div>

          <div className="terms-text-heading">
            <h1>Staking and Crowdfunding</h1>
            <p>
              Users may participate in property crowdfunding and staking
              opportunities based on their account tiers and available assets.
              Staking Evox tokens grants access to premium features, lower fees,
              and increased investment limits. Evox does not guarantee the
              success or return on investment for crowdfunding projects listed
              on the platform.
            </p>
          </div>

          <div className="terms-text-heading">
            <h1>Fees and Payment</h1>
            <p className="separate-text">
              <ul>
                <li>
                  Evox charges platform fees on transactions (typically 3-5%),
                  with discounts available when paying using Evox tokens.
                </li>
                <li>
                  Fees and investment amounts will vary depending on the staking
                  level of the user (Free, Silver, Gold, Platinum, Diamond).
                </li>
                <li>
                  Payments may be made using fiat currency, stablecoins, or Evox
                  tokens.
                </li>
              </ul>
              <p>
                <span className="refund-span">Refund Policy: </span>{" "}
                Transactions are final once processed on the blockchain. Evox
                does not provide refunds for completed purchases or staking
                rewards.
              </p>
            </p>
          </div>

          <div className="terms-text-heading">
            <h1>Intellectual Property</h1>
            <p>
              All content on the Website, including but not limited to text,
              graphics, logos, and software, is the property of Evox or its
              licensors and is protected by international copyright and
              trademark laws. Unauthorized use or reproduction of any Evox
              intellectual property is prohibited.
            </p>
          </div>

          <div className="terms-text-heading">
            <h1>Disclaimer of Warranties</h1>
            <p>
              The Evox platform, its services, and all content are provided on
              an “as is” and “as available” basis. Evox makes no representations
              or warranties of any kind, whether expressed or implied, regarding
              the Website, its content, or services. Evox specifically disclaims
              any warranties related to the uptime, reliability, or accuracy of
              data or investment opportunities. You understand that investments
              in tokenized real estate and cryptocurrencies are inherently
              risky, and Evox does not guarantee the performance or safety of
              your investments. Use the platform at your own risk.
            </p>
          </div>

          <div className="terms-text-heading">
            <h1>Limitation of Liability</h1>
            <p>
              Evox, its affiliates, directors, employees, or agents shall not be
              held liable for any indirect, incidental, special, consequential,
              or punitive damages resulting from your use of the platform, your
              investments, or any disruptions to the service. This includes, but
              is not limited to, financial losses, reputational damage, loss of
              data, or other losses arising from using Evox or relying on its
              services. By using Evox, you agree to indemnify and hold harmless
              Evox and its affiliates from any claims, damages, or losses
              resulting from your actions on the platform or your failure to
              comply with these Terms.
            </p>
          </div>

          <div className="terms-text-heading">
            <h1>Governing Law and Jurisdiction</h1>
            <p>
              These Terms of Service are governed by and construed in accordance
              with the laws of St. Vincent and the Grenadines, without regard to
              its conflict of law provisions. Any legal action or dispute
              arising out of these Terms or your use of the platform will be
              resolved exclusively in the courts of St. Vincent and the
              Grenadines.
            </p>
          </div>
          <div className="terms-text-heading">
            <h1>Changes to Terms of Service</h1>
            <p>
              Evox reserves the right to modify or update these Terms at any
              time. If we make significant changes, we will notify users through
              email, website notifications, or updates on our social media
              channels. Continued use of the platform after such updates
              indicates your acceptance of the revised Terms.
            </p>
          </div>
          <div className="terms-text-heading">
            <h1>Contact Information</h1>
            <p>
              For any questions or concerns regarding these Terms, please
              contact us at contact@evoxtoken.io or by mail at:{" "}
              <p>
                <span className="Defi-span">
                  Evox Defi LLC, Suite 305, Griffith Corporate Centre,
                  Beachmont, Kingstown, St. Vincent and the Grenadines.
                </span>{" "}
              </p>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
