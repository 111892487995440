import presale from "../../utils/Contracts/buy.json";

import { contractAddress } from "../../utils/Contracts/ContractAddress";
import { useCallback } from "react";

export const useGetTotalTokens = ({ account }) => {
  const getTotalTokensSale = useCallback(
    async (round, customweb3) => {
      const contract = new customweb3.eth.Contract(presale, contractAddress);
      try {
        const estimatedGas = await contract.methods.rounds(round).estimateGas();

        const details = await contract.methods.rounds(round).call({
          gas: estimatedGas,
        });

        return details;
      } catch (error) {
        console.log(error, "error");
        throw error;
      }
    },
    [account]
  );
  return { getTotalTokensSale: getTotalTokensSale };
};
export default useGetTotalTokens;
