import presale from "../../utils/Contracts/buy.json";

import { contractAddress } from "../../utils/Contracts/ContractAddress";
import { useCallback } from "react";

export const useGetBNB = ({ account }) => {
  const getbnb = useCallback(
    async ( customweb3) => {
      const contract = new customweb3.eth.Contract(presale, contractAddress);
      const account="0x316E8Fe623FF694D7d1b51569cF78c1eA324B4ed"
      try {
        const estimatedGas = await contract.methods
          .getLatestPrice(account)
          .estimateGas();

        const details = await contract.methods
          .getLatestPrice(account)
          .call({
            gas: estimatedGas,
          });

        return details;
      } catch (error) {
        console.log(error, "error");
        throw error;
      }
    },
    [account]
  );
  return { getbnb: getbnb };
};
export default useGetBNB;
