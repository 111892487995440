import React from "react";
import Navbar from "../../Components/ContactUs/Navbar/Navbar";
import TextField from "@mui/material/TextField";
import "./contactUs.css";
import { styled } from "@mui/material/styles";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import XIcon from "@mui/icons-material/X";
import { useNavigate } from "react-router-dom";

const iconStyle = {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#323232", // Default background color
  borderRadius: "50%",
  padding: "10px",
  transition: "background-color 0.3s",
  cursor: "pointer",
};

const iconHoverStyle = {
  backgroundColor: "#139ED5", // Background color on hover
};

const iconStyleInner = {
  color: "white", // Icon color
  fontSize: "18px", // Icon size
};

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    width: "100% !important",
    height: "139px !important",
    padding: "12.86px 17.45px !important",
    borderRadius: "9.18px !important",
    // border: '0.92px solid #FFFFFF !important',
    backgroundColor: "transparent !important",
    color: "#FFFFFF !important",
    fontFamily: "Poppins !important",
    "& fieldset": {
      border: "0.92px solid #FFFFFF !important",
    },
    "&:hover fieldset": {
      border: "0.92px solid #FFFFFF !important",
    },
    "&.Mui-focused fieldset": {
      border: "0.92px solid #FFFFFF !important",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#979797 !important",
    fontFamily: "Poppins !important",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#979797 !important",
  },
  "& .MuiInputBase-input": {
    color: "#FFFFFF !important",
    fontFamily: "Poppins !important",
  },
});
const ContactUs = () => {
  const navigate = useNavigate();
  return (
    <div className="Contact-Main">
      <div className="Contact-Top1">
        <div>
          <img
            src="/ContactUS_Images/logo.svg"
            alt="logo"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          />
        </div>
        <div className="Contact-Top1-inner">
          <div className="Contact-Top1-1">
            <p className="Contact-Top1-1-text">FOLLOW US</p>
            <p className="Contact-Top1-1-text1">
              Feel free to follow us on social media, you can get all the news
              and update on our platform and its easier to stay up to date
            </p>
            <div className="Contact-Top1-1-1">
              <div
                style={iconStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    iconHoverStyle.backgroundColor)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    iconStyle.backgroundColor)
                }
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    "https://www.instagram.com/evox.token/?fbclid=IwZXh0bgNhZW0CMTEAAR2lp9m3bnCTjpf06m6Up8JdAdaPNE25NTkyuJpfO34bVLS0s0TS3hONQT0_aem_Koc2xR2nywzOAMi6L3RFXA",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                <InstagramIcon style={iconStyleInner} />
              </div>
              <div
                style={iconStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    iconHoverStyle.backgroundColor)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    iconStyle.backgroundColor)
                }
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    "https://t.me/evoxtoken",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                <TelegramIcon style={iconStyleInner} />
              </div>
              <div
                style={iconStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    iconHoverStyle.backgroundColor)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    iconStyle.backgroundColor)
                }
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    "https://x.com/EvoxToken",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                <XIcon style={iconStyleInner} />{" "}
                {/* X is represented by TwitterIcon */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Contact-Top2">
        <Navbar />
        <div className="Contact-Top2-1">
          <div className="Contact-Top2-11">
            <div className="Contact-Top2-11-1">
              <p className="Contact-Top2-11-1-text1">EVOX</p>
              <p className="Contact-Top2-11-1-text2">
                Evox is a blockchain platform for real estate, enabling
                decentralised property investment. With tokenisation, it offers
                secure, transparent, and global opportunities for both small and
                large investors.
              </p>
            </div>
            <div className="Contact-Top2-11-2">
              <div className="Contact-Top2-11-2-div">
                <div className="Contact-Top2-11-2-inner">
                  <img src="/ContactUS_Images/loc.svg" alt="" />
                  <p className="Contact-Top2-11-2-inner-text">
                    Suite 305, Griffith Corporate Centre, Beachmont, Kingstown,
                    St. Vincent and the Grenadines.
                  </p>
                </div>
                <div className="Contact-Top2-11-2-inner">
                  <img src="/ContactUS_Images/mail.svg" alt="" />
                  <p className="Contact-Top2-11-2-inner-text">
                    Contact@evoxtoken.io
                  </p>
                </div>
                <div className="Contact-Top2-11-2-inner">
                  <img src="/ContactUS_Images/call.svg" alt="" />
                  <p className="Contact-Top2-11-2-inner-text">
                    +1 386-688-3295
                  </p>
                </div>
              </div>
            </div>

            <button className="Contact-Top1-1-btn">
              <p className="Contact-Top1-1-btn-text">
                 Gateway to Invest in Realty Across the Globe
              </p>
              <div className="image-container">
                <img
                  src="/ContactUS_Images/buttonicon.svg"
                  alt="Notifications"
                  className="rotate-image"
                />
                <img
                  src="/ContactUS_Images/btnarrowicon.svg"
                  alt="Arrow"
                  className="centered-image"
                />
              </div>
            </button>
          </div>
          <div className="Contact-Top2-12">
            <p className="Contact-Top2-12-text">Get in touch</p>

            <div className="Contact-Top2-12-1">
              <input
                type="text"
                placeholder="Name"
                className="Contact-Top2-12-1-input"
              />
              <input
                type="text"
                placeholder="Phone"
                className="Contact-Top2-12-1-input"
              />
              <input
                type="email"
                placeholder="Email"
                className="Contact-Top2-12-1-input"
              />
              <StyledTextField
                id="outlined-multiline-static"
                label="Message"
                multiline
                rows={4}
                variant="outlined"
              />
              <button type="submitt" className="Contact-Top2-12-1-button">
                Submit Now
              </button>
            </div>
          </div>
        </div>
        <button className="Contact-Top1-1-btn1">
          <p className="Contact-Top1-1-btn-text">
             Gateway to Invest in Realty Across the Globe
          </p>
          <div className="image-container">
            <img
              src="/ContactUS_Images/buttonicon.svg"
              alt="Notifications"
              className="rotate-image"
            />
            <img
              src="/ContactUS_Images/btnarrowicon.svg"
              alt="Arrow"
              className="centered-image"
            />
          </div>
        </button>
      </div>
    </div>
  );
};

export default ContactUs;
