import React from "react";
import "./home.css";
import Buy from "../../Components/Buy/Buy";
import RoadMap from "../../Components/RoadMap/RoadMap";
import Token from "../../Components/Token/Token";
import Footer from "../../Components/Footer/Footer";
import MUICard from "../../Components/Card/UpdateCard";
import Navbar from "../../Components/ContactUs/Navbar/Navbar";
import Cardsm from "../../Components/Card/Card";
import { useMediaQuery } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import XIcon from "@mui/icons-material/X";
import { ErrorToast, SuccessToast } from "../../Components/toast/Toast";
import { useNavigate } from "react-router-dom";

const iconStyle = {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#323232", // Default background color
  borderRadius: "50%",
  padding: "10px",
  transition: "background-color 0.3s",
  cursor: "pointer",
};
const iconStyleSm = {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  // backgroundColor: "white",
  borderRadius: "50%",
  padding: "5px",
  transition: "background-color 0.3s",
  cursor: "pointer",
};
const iconStyleInnerSm = {
  color: "white",
  fontSize: "14px", // Icon size
};
const iconHoverStyle = {
  backgroundColor: "#139ED5", // Background color on hover
};

const iconStyleInner = {
  color: "white", // Icon color
  fontSize: "18px", // Icon size
};
const Home = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:768px)");

  const connectorId = localStorage.getItem("connectorId");

  return (
    <div style={{ overflow: "hidden", position: "relative" }}>
      {/* <WalletConnect open={open} handleClose={handleClose}/> */}
      {/* <ConnectButton/> */}

      {/* {isError && <p>Error: {error.message}</p>}
      {isSuccess && <p>Contract write successful! Hash: {data}</p>} */}
      {/* <button onClick={handleWriteContract}>connect modal</button> */}
      <div style={{ position: "relative" }}>
        <img src="/Images/Home/building.svg" alt="" className="home__build" />
      </div>
      <div className="home__main">
        <div className="home__main-left">
          <div>
            <img src="/ContactUS_Images/logo.svg" alt="logo" style={{cursor:"pointer"}} onClick={() => navigate('/')} />
          </div>
          <div className="Contact-Top1-inner">
            <div className="home-Top1-1">
              <p className="Contact-Top1-1-text">FOLLOW US</p>
              <p className="Contact-Top1-1-text1">
                Feel free to follow us on social media, you can get all the news
                and update on our platform and its easier to stay up to date
              </p>
              <div className="Contact-Top1-1-1">
                <div
                  style={iconStyle}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconHoverStyle.backgroundColor)
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconStyle.backgroundColor)
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      "https://www.instagram.com/evox.token/?fbclid=IwZXh0bgNhZW0CMTEAAR2lp9m3bnCTjpf06m6Up8JdAdaPNE25NTkyuJpfO34bVLS0s0TS3hONQT0_aem_Koc2xR2nywzOAMi6L3RFXA",
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  <InstagramIcon style={iconStyleInner} />
                </div>
                <div
                  style={iconStyle}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconHoverStyle.backgroundColor)
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconStyle.backgroundColor)
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      "https://t.me/evoxtoken",
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  <TelegramIcon style={iconStyleInner} />
                </div>
                <div
                  style={iconStyle}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconHoverStyle.backgroundColor)
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconStyle.backgroundColor)
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      "https://x.com/EvoxToken",
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  <XIcon style={iconStyleInner} />{" "}
                  {/* X is represented by TwitterIcon */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home__main-right">
          <div className="navnardiv">
            <Navbar />
          </div>
          <div className="home-Top1-inner">
            <div className="home-Top1-2">
              <div>
                <img src="/Images/Home/globe.svg" alt="globe" />
              </div>
              <div>
                <p className="home-Top1-1-text">FOLLOW US</p>
                <p className="home-Top1-1-text1">
                  Feel free to follow us on social media, you can get all the
                  news and update on our platform and its easier to stay up to
                  date
                </p>
              </div>
              <div className="home-Top1-1-1">
                <div
                  style={iconStyleSm}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconHoverStyle.backgroundColor)
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconStyle.backgroundColor)
                  }
                >
                  <InstagramIcon style={iconStyleInnerSm} />
                </div>
                <div
                  style={iconStyleSm}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconHoverStyle.backgroundColor)
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconStyle.backgroundColor)
                  }
                >
                  <TelegramIcon style={iconStyleInnerSm} />
                </div>
                <div
                  style={iconStyleSm}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconHoverStyle.backgroundColor)
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      iconStyle.backgroundColor)
                  }
                >
                  <XIcon style={iconStyleInnerSm} />{" "}
                  {/* X is represented by TwitterIcon */}
                </div>
              </div>
            </div>
          </div>
          <div className="home__main-right-card">
            {isSmallScreen ? <Cardsm /> : <MUICard />}
            <button className="home-Top1-1-btn">
              <p className="Contact-Top1-1-btn-text">
                 Gateway to Invest in Realty Across the Globe
              </p>
              <div className="image-container">
                <img
                  src="/ContactUS_Images/buttonicon.svg"
                  alt="Notifications"
                  className="rotate-image"
                />
                <img
                  src="/ContactUS_Images/btnarrowicon.svg"
                  alt="Arrow"
                  className="centered-image"
                />
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="button_div">
        <button className="home-Top1-1-btn1">
          <p className="Contact-Top1-1-btn-text">
             Gateway to Invest in Realty Across the Globe
          </p>
          <div className="image-container">
            <img
              src="/ContactUS_Images/buttonicon.svg"
              alt="Notifications"
              className="rotate-image"
            />
            <img
              src="/ContactUS_Images/btnarrowicon.svg"
              alt="Arrow"
              className="centered-image"
            />
          </div>
        </button>
      </div>
      <Buy />
      <RoadMap />
      <Token />
      <Footer />
    </div>
  );
};

export default Home;
