import presale from "../../utils/Contracts/buy.json";

import { contractAddress } from "../../utils/Contracts/ContractAddress";
import { useCallback } from "react";

export const useGetTotalPurchase = ({ account }) => {
  const getPurchase = useCallback(
    async (account, customweb3) => {
      const contract = new customweb3.eth.Contract(presale, contractAddress);
      try {
        const estimatedGas = await contract.methods
          .currentRoundOfPurchase()
          .estimateGas();

        const details = await contract.methods
          .currentRoundOfPurchase()
          .call({
            gas: estimatedGas,
          });

        return details;
      } catch (error) {
        console.log(error, "error");
        throw error;
      }
    },
    [account]
  );
  return { getPurchase: getPurchase };
};
export default useGetTotalPurchase;
