import contractAbi from "../../utils/Contracts/buy.json";
import usdtabi from "../../utils/Contracts/usdtabi.json";


import presale from "../../utils/Contracts/buy.json";


import {
  contractAddress,
  UsdtContract,
} from "../../utils/Contracts/ContractAddress";
import { useCallback, useState } from "react";
export const useMint = ({ account }) => {
  const [statusState, setStatusState] = useState();
  const mintHook = useCallback(
    async (account, customweb3, amount, price) => {
      const contract = new customweb3.eth.Contract(contractAbi, contractAddress);
      const approvecontract = new customweb3.eth.Contract(
        usdtabi,
        UsdtContract
      );
      try {
        const feeinString = price;
        const valuesend = feeinString * Math.pow(10, 18)

        const approveestimatedGas = await approvecontract.methods
          .approve(contractAddress, valuesend)
          .estimateGas({
            from: account,
          });

        const checkAllowance = await approvecontract.methods
          .allowance(account, contractAddress)
          .call();
        const valueAllownace = customweb3.utils.fromWei(
          checkAllowance.toString(),
          "ether"
        );
        const valueInWei = customweb3.utils.toWei(amount.toString(), 'ether');


        console.log("allownace", checkAllowance, valueAllownace,feeinString);
        if (valueAllownace < valuesend) {
          setStatusState("Approving USDT for spending");
          const approve = await approvecontract.methods
            .approve(contractAddress, valuesend)
            .send({
              from: account,
              gas: approveestimatedGas,
            });
        }

        const estimatedGas = await contract.methods
          .buyWithToken(UsdtContract, valueInWei)
          .estimateGas({
            from: account,
          });

        setStatusState("Minting NFT(s)");

        const details = await contract.methods.buyWithToken(UsdtContract, valueInWei).send({
          from: account,
          gas: estimatedGas,
        });

        return details;
      } catch (error) {
        console.log(error, "errpr");
        throw error;
      }
    },
    [account]
  );
  return { mintHook: mintHook, statusState: statusState };
};
export default useMint;
