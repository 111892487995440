import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import ContactUs from "./Pages/ContactUs/ContactUs";
import { Web3ReactProvider } from "@web3-react/core";
import { ToastContainer } from "react-toastify";

import {
  hooks as walletConnectV2Hooks,
  walletConnectV2,
} from "./utils/Web3Connection/connectors/walletConnectV2";
import {
  hooks as metaMaskHooks,
  metaMask,
} from "./utils/Web3Connection/connectors/metaMask";

import useEagerConnect from "./hooks/Web3Connection/useEagerConnector";
import Whitepaper from "./Pages/Whitepaper/Whitepaper";
import Terms from "./Pages/Terms/Terms";
import Privacy from "./Pages/Privacy/Privacy";
import PrivatePresale from "./Pages/AccessPage/PrivatePresale";
import ProtectedRoute from "./utils/protectedRoute/ProtectedRoute";

const connectors = [
  [walletConnectV2, walletConnectV2Hooks],
  [metaMask, metaMaskHooks],
];

function App() {
  useEagerConnect();
  return (
    <Web3ReactProvider connectors={connectors}>
      {/* <WagmiProvider config={config}> */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // theme="light"
      />
      {/* <QueryClientProvider client={queryClient}> */}
      {/* <RainbowKitProvider
          // theme={darkTheme({
          //   accentColor: "#7b3fe4",
          //   accentColorForeground: "white",
          //   fontStack: "system",
          //   overlayBlur: "small",
          //   paddingTop:"20px"
          // })}
        > */}
      <BrowserRouter>
        <div className="container-f">
          <div className="container-custom">
            <Routes>
              <Route
                path="/Home"
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/about"
                element={
                  <ProtectedRoute>
                    <About />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/contact"
                element={
                  <ProtectedRoute>
                    <ContactUs />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/terms"
                element={
                  <ProtectedRoute>
                    <Terms />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Privacy"
                element={
                  <ProtectedRoute>
                    <Privacy />
                  </ProtectedRoute>
                }
              />
              <Route path="/" element={<PrivatePresale />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
      {/* </RainbowKitProvider>
      </QueryClientProvider> */}
      {/* </WagmiProvider> */}
    </Web3ReactProvider>
  );
}

export default App;
