import contractAbi from "../../utils/Contracts/buy.json";
import { contractAddress } from "../../utils/Contracts/ContractAddress";
import { useCallback, useState } from "react";

export const useMintWithBNB = ({ account }) => {
  const [statusState, setStatusState] = useState();

  const mintHookBNB = useCallback(
    async (account, customweb3, amount, bnbPrice) => {
      const contract = new customweb3.eth.Contract(contractAbi, contractAddress);

      try {
        // Convert BNB price to Wei (assuming bnbPrice is in BNB and not in Wei)
        const bnbPriceInWei = customweb3.utils.toWei(bnbPrice.toString(), "ether");
        const amountInWei=customweb3.utils.toWei(amount.toString(), "ether");

        console.log(amount, bnbPrice,bnbPriceInWei,amountInWei)


        // Calculate the BNB required in Ether (then convert to Wei)
        // const bnbRequired = (amount * Math.pow(10, 18)) / bnbPriceInWei; // amount is the token amount, bnbPrice in Wei

        // Convert BNB required to Wei
        // const bnbRequiredInWei = customweb3.utils.toWei(bnbRequired.toString(), "ether");

        // Estimate gas for the transaction
        const estimatedGas = await contract.methods
          .buyWithBNB(amountInWei) // amount is the number of tokens to buy
          .estimateGas({
            from: account,
            value: bnbPriceInWei, // Send the required BNB amount in Wei
          });

        setStatusState("Purchasing with BNB");

        // Send the transaction with the correct BNB value in Wei
        const details = await contract.methods
          .buyWithBNB(amountInWei)
          .send({
            from: account,
            value: bnbPriceInWei, // Sending BNB along with the transaction in Wei
            gas: estimatedGas,
          });

        return details;
      } catch (error) {
        console.log(error, "error");
        throw error;
      }
    },
    [account]
  );

  return { mintHookBNB: mintHookBNB, statusState: statusState };
};

export default useMintWithBNB;
