import contractAbi from "../../utils/Contracts/buy.json";
import usdtabi from "../../utils/Contracts/usdtabi.json";

import presale from "../../utils/Contracts/buy.json";

import {
  contractAddress,
  UsdtContract,
} from "../../utils/Contracts/ContractAddress";
import { useCallback, useState } from "react";
export const useClaim = ({ account }) => {
  const [statusState, setStatusState] = useState();
  const claimHook = useCallback(
    async (account, customweb3) => {
      const contract = new customweb3.eth.Contract(
        contractAbi,
        contractAddress
      );
      try {
        const estimatedGas = await contract.methods.claimTokens().estimateGas({
          from: account,
        });

        const details = await contract.methods.claimTokens().send({
          from: account,
          gas: estimatedGas,
        });

        return details;
      } catch (error) {
        console.log(error, "errpr");
        throw error;
      }
    },
    [account]
  );
  return { claimHook: claimHook };
};
export default useClaim;
