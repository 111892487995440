import presale from "../../utils/Contracts/buy.json";

import { contractAddress } from "../../utils/Contracts/ContractAddress";
import { useCallback } from "react";

export const useGetTotalEvox = ({ account }) => {
  const getEvox = useCallback(
    async (account, round, customweb3) => {
      const contract = new customweb3.eth.Contract(presale, contractAddress);
      try {
        const estimatedGas = await contract.methods
          .userPurchases(account, round)
          .estimateGas();

        const details = await contract.methods
          .userPurchases(account, round)
          .call({
            gas: estimatedGas,
          });

        return details;
      } catch (error) {
        console.log(error, "error");
        throw error;
      }
    },
    [account]
  );
  return { getEvox: getEvox };
};
export default useGetTotalEvox;
