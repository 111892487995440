import { Modal, Button } from "react-bootstrap";
import React from "react";
import { useMediaQuery } from "@mui/material";
import useAuth from "../../../hooks/Web3Connection/useAuth";

const ConnectWallet = ({ showWallet, onHide }) => {
  const isSmallScreen = useMediaQuery("(max-width:768px)");

  const { login } = useAuth();

  const connectMetaMask1 = async () => {
    try {
      onHide();
      await login("injected", 56);
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
    } catch (err) {
      console.log("err", err);
    }
  };

  const trustWallet = async () => {
    try {
      onHide();
      await login("walletconnect", 56);
      localStorage.setItem("connectorId", "walletconnect");
      localStorage.setItem("flag", "true");
      // setLog(true);
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <Modal
      show={showWallet}
      onHide={onHide}
      centered
      className="customfourth-modal"
    >
      <Modal.Body className="modalbody">
        <div className="top">
          <p className="Connecttext">Connect wallet</p>
          <Button
            style={{
              padding: "0px",
              width: "24px",
              height: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "transparent",
              border: "none",
            }}
            className="btnwithpostion"
            variant="success"
            onClick={onHide}
          >
            <img src="/Images/Modal/cross.svg" alt="Fox" />
          </Button>
        </div>

        <div className="lineswithcontent">
          {!isSmallScreen && (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                connectMetaMask1();
              }}
              className="Metamask"
            >
              <div className="first">
                <img src="/Images/Modal/Fox.svg" alt="Fox" />
                <p className="metatext">METAMASK</p>
              </div>
            </div>
          )}

          <div style={{ cursor: "pointer" }} className="Metamask">
            <div
              onClick={() => {
                trustWallet();
              }}
              className="first"
            >
              <img src="/Images/Home/wallet-connect.png" alt="Fox" />
              <p className="metatext">WalletConnect</p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConnectWallet;
