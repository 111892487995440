import { Modal, Button } from "react-bootstrap";
import React, { useState } from "react";
import '../Modal.css'

const Success = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} centered dialogClassName="custom-modal">
      <Modal.Body className="text-center">
        <p className="modal-text">You have successfully Claimed Evox token</p>
        <Button
          style={{
            width: "135px",
            height: "37px",
            backgroundColor: "#13D561",
            borderColor: "#13D561",

            borderRadius: "20px",
            fontSize: "14px",
            fontWeight: "600",
            color: "#FFFFFF",
            textAlign: "center",
          }}
          variant="success"
          onClick={onHide}
        >
          GOT IT
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default Success;
