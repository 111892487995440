import React from "react";
import "./token.css";
import CanvasJSReact from "@canvasjs/react-charts";
import DoughnutChart from "../Chart/Chart";
// import { Padding } from "@mui/icons-material";
// import { color } from "chart.js/helpers";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Token = () => {
  const options = {
    animationEnabled: true,
    containerProps: {
      width: "500px", // Set the desired width
      height: "500px", // Set the desired height
    },
    title: {
      text: "1 Billion",
      verticalAlign: "center",
    },
    subtitles: [
      {
        // text: "Total Supply",
        verticalAlign: "center",
        fontSize: 24,
        dockInsidePlotArea: true,
        PaddingBottom: "0",
      },
      {
        // text: "1 Billion",
        verticalAlign: "center",
        fontSize: 24,
        dockInsidePlotArea: true,
        PaddingBottom: "60",
      },
    ],
    data: [
      {
        type: "doughnut",
        // showInLegend: true,
        indexLabel: "{name}: {y}",
        indexLabelLineThickness: 0,
        indexLabelFontFamily: "Poppins",
        indexLabelBackgroundColor: "#84DCFF",
        indexLabelFontColor: "white",
        yValueFormatString: "#,###'%'",
        dataPoints: [
          { name: "Ecosystem", y: 22, color: "#84DCFF", cursor: "pointer" },
          { name: "marketing", y: 16, color: "#4CC8F9", cursor: "pointer" },
          { name: "Liquidity", y: 15, color: "#40C9FF", cursor: "pointer" },
          { name: "Team", y: 12, color: "#149FD6", cursor: "pointer" },
          { name: "Private", y: 10, color: "#128CBC", cursor: "pointer" },
          { name: "Public", y: 9, color: "#09729B", cursor: "pointer" },
          { name: "Seed", y: 6, color: "#086589", cursor: "pointer" },
          { name: "Advisory", y: 5, color: "#06465F", cursor: "pointer" },
          { name: "Pre Seed", y: 5, color: "#053A4F", cursor: "pointer" },
        ],
      },
    ],
  };
  return (
    <div className="token__main">
      <div className="road__main-head">
        <h1>Tokenomics</h1>
        <p>
          Each aspect of the token utility and distribution is designed to
          ensure that Evox token holders from all parts of the ecosystem — from
          developers and investors to casual users — find value in using and
          holding Evox tokens. This balanced approach aims to create a
          sustainable economic model that supports the platform's growth and the
          appreciation of token value over time.
        </p>
      </div>
      <div className="token__main-chart">
        <img src="/Images/Home/token.png" alt="" />
        {/* <DoughnutChart /> */}
        {/* <CanvasJSChart options={options} className="token__main-chart-cs" /> */}
      </div>
      <div className="home__graph">
        <img src="/Images/Home/graph.png" alt="/" />
      </div>
    </div>
  );
};

export default Token;
