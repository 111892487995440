import React from "react";
import "./roadMap.css";

const RoadMap = () => {
  return (
    <div className="road__main">
      <div className="road__main-head">
        <h1>Roadmap</h1>
        <p>
          Evox's journey is all about innovation, user satisfaction, and market
          leadership in the blockchain real estate sector. Our roadmap
          highlights the key milestones and strategic developments that showcase
          our commitment to leveraging emerging trends and technologies. Dive
          into our exciting future and see how Evox is set to revolutionize real
          estate investment!
        </p>
      </div>
      <div className="roadmap-container">
        <div className="roadmap-container-body">
          <div className="roadmap-card q1">
            <h2>Q1</h2>
            <p>Q1 2024: Market Readiness and Pre-Launch Marketing </p>
            <ul>
              <li>
                Final market analysis and readiness assessments to align the
                platform with current real estate and blockchain market
                conditions.
              </li>
              <li>
                Intensification of pre-launch marketing activities to build
                momentum and educate potential users on the benefits of the
                platform.
              </li>
            </ul>
          </div>

          <div className="roadmap-card q3">
            <h2>Q3</h2>
            <p>Q3 2024: Public Token Raise and Official Platform Launch</p>
            <ul>
              <li>
                Execution of a public token sale to expand investor base and
                increase platform liquidity.
              </li>
              <li>
                Official launch of the Evox platform to the general public with
                full functionalities and support structures in place.
              </li>
            </ul>
          </div>
        </div>
        <div className="roadmap-container-body1">
          <div className="roadmap-card q2">
            <h2>Q2</h2>
            <p>Q2 2024: Pre-Seed and Seed Token Raise</p>
            <ul>
              <li>
                Initiation of pre-seed and seed funding phases to raise capital
                through token sales, targeting early investors and blockchain
                enthusiasts.
              </li>
              <li>
                Use of raised funds to enhance technological capabilities and
                expand operational capacity in anticipation of public launch.
              </li>
            </ul>
          </div>
          {/* <div class="roadmap-card q4">
            <h2>Q4</h2>
            <p>Q4 2024: Frontend Development and Initial Security Measures</p>
            <ul>
              <li>
                Development of the frontend interface, integrating user
                experience design with real-time blockchain functionalities.
              </li>
              <li>
                Establishment of initial security protocols, including
                encryption methods and wallet security features, based on
                industry standards.
              </li>
            </ul>
          </div> */}
        </div>
      </div>
      <div className="roadmap-container-sm">
        <div className="roadmap-container-body">
          <div className="roadmap-card q1">
            <h2>Q1</h2>
            <p>Q1 2024: Market Readiness and Pre-Launch Marketing </p>
            <ul>
              <li>
                Final market analysis and readiness assessments to align the
                platform with current real estate and blockchain market
                conditions.
              </li>
              <li>
                Intensification of pre-launch marketing activities to build
                momentum and educate potential users on the benefits of the
                platform.
              </li>
            </ul>
          </div>
          <div className="roadmap-card q2">
            <h2>Q2</h2>
            <p>Q2 2024: Pre-Seed and Seed Token Raise</p>
            <ul>
              <li>
                Initiation of pre-seed and seed funding phases to raise capital
                through token sales, targeting early investors and blockchain
                enthusiasts.
              </li>
              <li>
                Use of raised funds to enhance technological capabilities and
                expand operational capacity in anticipation of public launch.
              </li>
            </ul>
          </div>
        </div>
        <div className="roadmap-container-body1">
          <div className="roadmap-card q3">
            <h2>Q3</h2>
            <p>Q3 2024: Public Token Raise and Official Platform Launch</p>
            <ul>
              <li>
                Execution of a public token sale to expand investor base and
                increase platform liquidity.
              </li>
              <li>
                Official launch of the Evox platform to the general public with
                full functionalities and support structures in place.
              </li>
            </ul>
          </div>
          {/* <div class="roadmap-card q4">
            <h2>Q4</h2>
            <p>Q4 2024: Frontend Development and Initial Security Measures</p>
            <ul>
              <li>
                Development of the frontend interface, integrating user
                experience design with real-time blockchain functionalities.
              </li>
              <li>
                Establishment of initial security protocols, including
                encryption methods and wallet security features, based on
                industry standards.
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default RoadMap;
