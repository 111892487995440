import React, { useState } from "react";
import "./PrivatePresale.css";
import { useNavigate } from "react-router-dom";
import { ErrorToast } from "../../Components/toast/Toast";
function PrivatePresale() {
  const [accessCode, setAccessCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    setAccessCode(e.target.value);
  };

  const handleContinue = () => {
    const validAccessCode = "4D1F7E2ACA5844D1B5A6ABC123456789";

    if (accessCode === validAccessCode) {
      localStorage.setItem("accessCode", accessCode);
      navigate("/Home");
    } else {
      ErrorToast("Invalid access code. Please try again.");
    }
  };
  return (
    <>
      <div className="PrivatePresale_BOx">
        <div className="PrivatePresale_Container">
          <div className="Private_Presale_OverAll_Container">
            <div className="PrivatePresale_LOgoAnd_Text">
              <img src="/ContactUS_Images/logo.svg" alt="logo" />
              <h2 className="PrivatePresale_HText">Private Presale</h2>
            </div>
            <div className="PrivatePresale_InputTypeAnd-text_container">
              <p className="Privatpresale_enter_code_text">
                Please enter access code to continue
              </p>
              <input
                className="Privatepresale_input_Text"
                type="text"
                placeholder="Enter Access Code"
                value={accessCode}
                onChange={handleInputChange}
              />
              <button className="PrivatePresale_Btn" onClick={handleContinue}>
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivatePresale;
