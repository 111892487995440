import React from "react";
import "./buy.css";

const cardData = [
  {
    number: "1",
    text: "Make sure you are on the website: https://evoxtoken.io/",
  },
  { number: "2", text: "" },
  {
    number: "3",
    text: "Ensure you have 0.002 BNB to pay for gas fee and have sufficient USDT to buy EVOX Tokens",
  },
  { number: "4", text: "Connect MetaMask or any other wallet." },
  {
    number: "5",
    text: "Make sure you have USDT (BEP20) or BNB in your wallet.",
  },
  { number: "6", text: "Enter the quantity of EVOX Tokens you want to buy." },
  { number: "7", text: "Click on the 'Buy' button." },
  { number: "8", text: "You will receive EVOX tokens in your wallet." },
];

const contractAddress = "0x700084A44960fd56efC0444369fA515c6812a324";
const contractLink = `https://bscscan.com/address/${contractAddress}#readContract`;
const Buy = () => {
  function truncateAddress(address) {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  }

  // Function to handle copy of the contract address
  const handleCopyAddress = () => {
    navigator.clipboard.writeText(contractLink).then(
      () => {
        alert("Address copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };
  return (
    <div className="buy__main">
      <div className="card__main-txt">
        <h1>How to Buy</h1>
        <p>
          Welcome to the EVOX token purchase guide! Follow these straightforward
          steps to easily acquire EVOX tokens and join the future of real estate
          investment. Whether you're new to cryptocurrency or a seasoned
          investor, our platform makes it simple and secure to buy EVOX tokens.
          Let's get started!
        </p>
      </div>
      <div className="buy__main-body">
        {cardData?.map((card, index) => (
          <div key={index} className="card__main-body-txt">
            <h2>{card.number}</h2>
            <p>{card.text}</p>
            {index === 1 && (
              <div>
                <p>
                  Add EVOX token address to MetaMask:{" "}
                  {truncateAddress(contractAddress)}
                </p>
                <button
                  className="add-token-button"
                  onClick={handleCopyAddress}
                >
                  Copy address
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Buy;
