import presale from "../../utils/Contracts/usdtabi.json";

import { UsdtContract } from "../../utils/Contracts/ContractAddress";
import { useCallback } from "react";

export const useGetUsdtBalance = ({ account }) => {
  const getBalnace = useCallback(
    async (account, customweb3) => {
      const contract = new customweb3.eth.Contract(presale, UsdtContract);
      try {
        const estimatedGas = await contract.methods
          .balanceOf(account)
          .estimateGas();

        const details = await contract.methods
          .balanceOf(account)
          .call({
            gas: estimatedGas,
          });

        return details;
      } catch (error) {
        console.log(error, "error");
        throw error;
      }
    },
    [account]
  );
  return { getBalnace: getBalnace };
};
export default useGetUsdtBalance;
